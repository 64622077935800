import React from "react";
import ceed from "../images/ceed/ceed.png";
import chetan from "../images/ceed/chetab-sma.png";
import { Link } from "gatsby";

const Banner = () => (
  <section id="banner-2" className="banner">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-10 text-center">
          <div className="banner-content">
            {/*<img src={ceed} alt="ceed"/>*/}
            <h1 className="lg-title mt-4 mb-5">
              <span className="ceedText lg-title">Ceed</span>
              Accelerator Program For A <span className="highlight">Focused</span> <br/> Startup Building
            </h1>
            <a href="https://bit.ly/2HooMXE" className="btn btn-hero btn-rounded" target="_blank">Join The
              Program</a>
          </div>

        </div>
      </div>
    </div>
    <div className="floating">
      <Link to="/contact">
        <div>
          <div className="custom-popover">
            <p>Contact Student CAP Coordinator</p>
          </div>
          <img src={chetan}/>
        </div>
      </Link>
    </div>
    <div className="scroll">
      <a><span></span></a>
    </div>
  </section>
);

export default Banner;
