import React from "react";
import startup1 from "../images/ceed/startup1.png";
import startup2 from "../images/ceed/startup2.png";
import startup3 from "../images/ceed/startup3.png";
import startup4 from "../images/ceed/startup4.png";
import startup5 from "../images/ceed/startup5.png";
import quickStay from "../images/ceed/quick-stay-founder.png";
import educaffe from "../images/ceed/educaffe-founder.png";
import bodyFitness from "../images/ceed/body-fitness-founder.png";
import ride from "../images/ceed/ride-founder.png";
import maali from "../images/ceed/maali-founder.png";

import data from "../data/mentors-with-picture";

const Partners = () => (
  <section id="outcome" className="section partners outcome">
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="section-heading">
          <h6 className="subtitle text-primary">SOME OF THE SUCCESSFUL STARTUPS OF CAP COHORT – I</h6>
          {/*<h2 className="display-4">Our Supporters</h2>*/}
        </div>
        <div className="col-lg-12">
          <div className="text-center">
            <div className="row justify-content-center">
              <div className="col-lg-2 col-md-3 column">
                <div className="team-block">
                  <div className="image">
                    <img src={quickStay} alt="" className="img-fluid"/>
                  </div>
                  <div className="team-content">
                    <div className="startup-name">
                      <img src={startup1} alt="" className="img-fluid"/>
                      <h4>Quick Stay</h4>
                    </div>
                    <p className="occupation">Founders - Jatin Madaan & Ishita Gupta</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 column">
                <div className="team-block">
                  <div className="image">
                    <img src={educaffe} alt="" className="img-fluid"/>
                  </div>
                  <div className="team-content">
                    <div className="startup-name">
                      <img src={startup2} alt="" className="img-fluid"/>
                      <h4>Educaffe</h4>
                    </div>
                    <p className="occupation">Founder - Nipun Sharma</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 column">
                <div className="team-block">
                  <div className="image">
                    <img src={ride} alt="" className="img-fluid"/>
                  </div>
                  <div className="team-content">
                    <div className="startup-name">
                      <img src={startup3} alt="" className="img-fluid"/>
                      <h4>Ridez Now</h4>
                    </div>
                    <p className="occupation">Founders - Ankit Mathuria, Vipul Sharma & Abhisar Ahuja</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 column">
                <div className="team-block">
                  <div className="image">
                    <img src={bodyFitness} alt="" className="img-fluid"/>
                  </div>
                  <div className="team-content">
                    <div className="startup-name">
                      <img src={startup4} alt="" className="img-fluid"/>
                      <h4>Body Fitness</h4>
                    </div>
                    <p className="occupation">Founder - Karanveer Singh Thakur</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-3 column">
                <div className="team-block">
                  <div className="image">
                    <img src={maali} alt="" className="img-fluid"/>
                  </div>
                  <div className="team-content">
                    <div className="startup-name">
                      <img src={startup5} alt="" className="img-fluid"/>
                      <h4>Your Maali</h4>
                    </div>
                    <p className="occupation">Founders - Shobhit Singh,Gagandeep and Sanchit </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Partners;
