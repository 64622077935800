import apoorve from "../images/ceed/mentor-apoorv.png";
import archana from "../images/ceed/mentor-archana.jpg";

const data = [{
  "name": "Apoorv Bamba",
  "title": "Co-founder & CEO, Ingenious Faces"
}, {
  "name": "Ritika Singh",
  "title": "Founder Kontent Factory"
}, {
  "name": "Vineet Khurana",
  "title": "VP Chandigarh Angels Network"
}, { "name": "Milind Kwatra", "title": "Founder - Hum Hain" }, {
  "name": "Akshay Ahuja",
  "title": "Entrepreneur & Alumni , Chitkara University"
}, { "name": "Raveen Walia", "title": "Expert International Sales" }, {
  "name": "Ajay Aggarwal",
  "title": "Digital Marketing Guru"
}, { "name": "Dr. Adarsh Aggarwal", "title": "Chief Mentor – CEED" }, {
  "name": "Sameer Sharma",
  "title": "Founder - Shoutlo"
},

  { "name": "Siddhartha Kumar", "title": "Dua Associates" },
  { "name": "Dinesh Singh", "title": "MD, Nation Cultus Consulting" },
  {
    "name": "Col (Dr.) KK Sharma",
    "title": "Dean UG Program, Chitkara University"
  }
  , {
    "name": "Kunal Nandwani",
    "title": "Founder – Utrade Solutions"
  },{
    "name": "Dr. Anjuman Arora",
    "title": "Chief Strategy Officer - DataToBiz"
  }

];

export default data;
