import React from "react";
import cap from "../images/ceed/cap.png";
import { Link } from "gatsby";

const About = () => (
  <section id="about" className="section about-us">
    <div className="container">
      <div className="row justify-content-end align-items-center ">
        <div className="col-lg-5 col-md-5">
          <div className="event-img">
            <img src={cap} alt="" className="img-fluid img-style"/>
          </div>
        </div>
        <div className="col-lg-1 col-md-1"/>
        <div className="col-lg-6 col-md-6">
          <div className="video-content">
            <h6 className="text-primary text-uppercase mb-3 subtitle">About CAP</h6>
            <h2 className="mb-4 display-4">A CHITKARA UNIVERSITY INITIATIVE TO BUILD CAMPUS STARTUPS</h2>
            <p className="mb-5">
              CAP - CEED ACCELERATOR PROGRAM is the most stellar initiative of Chitkara University to
              promote entrepreneurship in the North India. With a core aim to build campus startups and
              market ready enterprises, CAP is supported and run by Industry entrepreneurs, academicians
              and Angel Investors.
            </p>
            <Link to="/about" className="btn btn-hero btn-rounded">Read More</Link>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default About;
