import React from "react";
import Banner from "../components/banner";
import About from "../components/about";
import Mission from "../components/mission";
import Mentors from "../components/mentors";
import OtherMentors from "../components/otherMentors";
import CoreMentors from "../components/coreMentors";
import Gallery from "../components/gallery";
import Partners from "../components/partners";
import CohortTwo from "../components/cohortTwo";
import Outcome from "../components/outcome";
import Layout from "../components/layout";
import Winners from "../components/winners";
import Poster from "../components/poster";

const IndexPage = () => {
  return (
    <Layout isHomePage={true}>
      <Banner/>
      <About/>
      <Mission/>
      <CoreMentors/>
      <Mentors/>
      <OtherMentors/>
      <Gallery/>
      <Poster/>
      <Outcome/>
      <Winners/>
      <CohortTwo/>
      <Partners/>
    </Layout>
  );
};
export default IndexPage;
