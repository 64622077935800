import React from "react";
import can from "../images/ceed/CAN.png";
import cultus from "../images/ceed/cultus.png";
import chitkaraLogo from "../images/ceed/innovation.png";
import ceed from "../images/ceed/ceed black.png";
import ingenius from "../images/ceed/ingenius.png";

const Partners = () => (
  <section id="sponsors" className="section partners">
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-heading">
              <h6 className="subtitle text-primary">CAP PARTNERS</h6>
              {/*<h2 className="display-4">Our Supporters</h2>*/}
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="text-center">
            <div>
              <span className="partner-logo"><img src={can} alt="" className="img-fluid"/></span>
              <span className="partner-logo"><img src={cultus} alt="" className="img-fluid"/></span>

              <span className="partner-logo"><img src={ceed} alt="" className="img-fluid"/></span>
              <span className="partner-logo"><img src={chitkaraLogo} alt="" className="img-fluid"/></span>
              <span className="partner-logo"><img src={ingenius} alt="" className="img-fluid"/></span>
            </div>
            <div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Partners;
