import React from "react";

const Mission = () => (
  <section id="mission" className="section mission">
    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-heading">
            <h6 className="subtitle text-primary">CAP MISSION</h6>
            <h2 className="display-4">
              Building strong fundamentals of entrepreneurship
            </h2>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-4 col-md-6">
          <div className="feature-box py-5 mb-md-3">
            <div className="mb-3">
              <i className="ion-arrow-graph-up-right"></i>
              <h4 className="">Developing Entrepreneur Skills</h4>
            </div>
            <p>SKILLS developed include critical entrepreneurial and employability skills such as problem solving,
              business model development, data analysis/measurement and project execution.</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6">
          <div className="feature-box py-5 mb-md-3">
            <div className="mb-3">
              <i className="ion-briefcase"></i>
              <h4 className="">Easy Access to Investors</h4>
            </div>
            <p>CAP aims at funding the outstanding startups at the completion of the program. It aims in developing
              business concepts to a point where startups are ready to begin to seek angel investment.</p>
          </div>
        </div>

        <div className="col-lg-4 col-md-6">
          <div className="feature-box py-5 mb-md-3">
            <div className="mb-3">
              <i className="ion-ribbon-b"></i>
              <h4 className="">Leading Mentors</h4>
            </div>
            <p>With more of practicum and Industry experience approach The program is backed by top-notch industry
              mentors.</p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Mission;
