import React, { Component } from "react";
import group from "../images/ceed/group.jpg";
import b1 from "../images/ceed/group-pic.png";
import b2 from "../images/ceed/bg2.jpg";
import b3 from "../images/ceed/bg3.jpg";
//import LightBox from "react-image-lightbox";
//import "react-image-lightbox/style.css";
import Carousel,{ModalGateway,Modal} from 'react-images'
class Gallery extends Component {

  constructor (props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false
    };
  }

  componentDidMount () {

  }

  openLightBox (index) {
    this.setState({
      isOpen: true,
      photoIndex: index
    });

  }

  render () {
    const images = [group, b1, b2, b3];
    const { photoIndex, isOpen } = this.state;
    return (
      <section id="gallery" className="section gallery">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading">
                {/*<h6 className="subtitle text-primary">CAP COMMUNITY</h6>*/}
                <h6 className="subtitle text-primary">CAP FACULTY FROM INDUSTRY, ACADEMIA AND INVESTOR NETWORK TAKING CLASSES</h6>
                {/*<h2 className="display-4">Building Startups</h2>*/}
                {/*<h2 className="display-4">CAP FACULTY FROM INDUSTRY,ACADEMIA AND INVESTOR NETWORK TAKING CLASSES</h2>*/}
              </div>
            </div>
          </div>
          <div className="images">
            <div className="row gutter">
              {
                images.map((item, index) => {
                  return (
                    <div key={index} className="col-lg-6 column">
                      <a className="image"
                         style={{ backgroundImage: "url(" + images[index] + ")" }}
                         onClick={this.openLightBox.bind(this, index)}/>
                    </div>
                  );
                })
              }
            </div>
          </div>
          <ModalGateway>
            {isOpen ? (
              <Modal onClose={() => this.setState({ isOpen: false })}>
                <Carousel currentIndex={photoIndex} views={images.map((source)=>{
                  return {
                    source
                  }
                })} />
              </Modal>
            ) : null}
          </ModalGateway>
          {/*{*/}
            {/*isOpen && (*/}
              {/*<LightBox*/}
                {/*mainSrc={images[photoIndex]}*/}
                {/*nextSrc={images[(photoIndex + 1) % images.length]}*/}
                {/*prevSrc={images[(photoIndex + images.length - 1) % images.length]}*/}
                {/*onCloseRequest={() => this.setState({ isOpen: false })}*/}
                 {/*onMovePrevRequest={() =>*/}
                  {/*this.setState({*/}
                    {/*photoIndex: (photoIndex + images.length - 1) % images.length*/}
                  {/*})*/}
                {/*}*/}
                {/*onMoveNextRequest={() =>*/}
                  {/*this.setState({*/}
                    {/*photoIndex: (photoIndex + 1) % images.length*/}
                  {/*})*/}
                {/*}*/}
              {/*/>*/}
            {/*)}*/}
        </div>
      </section>
    );
  }
}

export default Gallery;
