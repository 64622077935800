import React from "react";
import sumeer from "../images/ceed/mentor-sumeer-core.jpg";
import adarsh from "../images/ceed/mentor-adarsh.png";
import neeraj from "../images/ceed/mentor-neeraj.png";

const CoreMentors = () => (
  <section id="core-mentors" className="section core mentors">

    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-heading">
            <h6 className="subtitle text-primary">CAP TEAM</h6>
            <h2 className="display-4">Meet The Core Team</h2>
          </div>
        </div>
      </div>

      <div className="row justify-content-center">

        <div className="col-lg-3 col-md-6 column">
          <div className="team-block">
            <div className="image">
              <img src={sumeer} alt="" className="img-fluid"/>
            </div>
            <div className="team-content">
              <h4>Mr. Sumeer Walia</h4>
              <p className="occupation">Program Architect</p>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 column">
          <div className="team-block">
            <div className="image">
              <img src={adarsh} alt="" className="img-fluid"/>
            </div>
            <div className="team-content">
              <h4>Dr. Adarsh Aggarwal</h4>
              <p className="occupation">Curriculum Head</p>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-md-6 column">
          <div className="team-block">
            <div className="image">
              <img src={neeraj} alt="" className="img-fluid"/>
            </div>
            <div className="team-content">
              <h4>Dr. Neeraj Kumar</h4>
              <p className="occupation">Cohort Coordinator</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>
);

export default CoreMentors;
