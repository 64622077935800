import React from "react";
import poster from "../images/ceed/group.jpg";
import { Link } from "gatsby";

const CohortTwo = () => (
  <section id="cohortTwo" className="section poster cohort-two">
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-heading">
              <h6 className="subtitle text-primary">COHORT - II</h6>
              {/*<h2 className="display-4">Our Supporters</h2>*/}
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <img src={poster}/>
          <p className="caption">CAP Cohort - II from batch of 2020-2021</p>
        </div>
        <div className="description">
          <p className="title">Launching CEED ACCELERATOR PROGRAM (CAP) Cohort-II</p>
          <p className="text">With a core aim to build campus startups and market ready enterprises, CEED is coming up
            CAP Cohort-II. CAP
            Cohort-II is a one semester long online program with 6 credits, fully funded by Chitkara University. CAP
            Cohort-II started from 18th July, 2020. Faculty for this cohort comprises of Industry Entrepreneurs,
            Academicians and Angel Investors.The cohort is superwised by an accomplished by Chitkara University’s
            Startup Governing Council & Committtee.</p>
          <Link to="/cohort-two" className="btn btn-hero btn-rounded">Read More</Link>
        </div>
      </div>
    </div>
  </section>
);

export default CohortTwo;
