import React, { Component } from "react";
import winner1 from "../images/ceed/winner1.png";
import winner2 from "../images/ceed/winner2.png";
//import LightBox from "react-image-lightbox";
//import "react-image-lightbox/style.css";
import Carousel, { ModalGateway, Modal } from "react-images";

class Gallery extends Component {

  constructor (props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false
    };
  }

  componentDidMount () {

  }

  openLightBox (index) {
    this.setState({
      isOpen: true,
      photoIndex: index
    });

  }

  render () {
    const images = [winner1, winner2];
    const { photoIndex, isOpen } = this.state;
    return (
      <section id="winners" className="section gallery winners">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-heading">
                {/*<h6 className="subtitle text-primary">CAP COMMUNITY</h6>*/}
                <h6 className="subtitle text-primary">CAP COHORT-I STARTUPS RAISED FUNDING AT INDIA FUND FEST 2020</h6>
                <p>Two startups incubated by Chitkara raised funding of Rs 1.3 Cr at “INDIA FUND FEST 2020” at Hyatt
                  Regency, Chandigarh</p>
              </div>
            </div>
          </div>
          <div className="images">
            <div className="row gutter">
              {
                images.map((item, index) => {
                  return (
                    <div key={index} className="col-lg-6 column">
                      <a className="image"
                         style={{ backgroundImage: "url(" + images[index] + ")" }}
                         onClick={this.openLightBox.bind(this, index)}/>
                    </div>
                  );
                })
              }
            </div>
          </div>
          <ModalGateway>
            {isOpen ? (
              <Modal onClose={() => this.setState({ isOpen: false })}>
                <Carousel currentIndex={photoIndex} views={images.map((source) => {
                  return {
                    source
                  };
                })}/>
              </Modal>
            ) : null}
          </ModalGateway>
          {/*{*/}
          {/*isOpen && (*/}
          {/*<LightBox*/}
          {/*mainSrc={images[photoIndex]}*/}
          {/*nextSrc={images[(photoIndex + 1) % images.length]}*/}
          {/*prevSrc={images[(photoIndex + images.length - 1) % images.length]}*/}
          {/*onCloseRequest={() => this.setState({ isOpen: false })}*/}
          {/*onMovePrevRequest={() =>*/}
          {/*this.setState({*/}
          {/*photoIndex: (photoIndex + images.length - 1) % images.length*/}
          {/*})*/}
          {/*}*/}
          {/*onMoveNextRequest={() =>*/}
          {/*this.setState({*/}
          {/*photoIndex: (photoIndex + 1) % images.length*/}
          {/*})*/}
          {/*}*/}
          {/*/>*/}
          {/*)}*/}
        </div>
      </section>
    );
  }
}

export default Gallery;
