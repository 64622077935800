import archana from "../images/ceed/mentor-archana.jpg";
import anjuman from "../images/ceed/mentor-anjuman.png";
import nalin from "../images/ceed/mentor-nalin.png";
import sandhir from "../images/ceed/mentor-sandhir.png";
import sunil from "../images/ceed/mentor-sunil.jpg";
import vivek from "../images/ceed/mentor-vivek.jpg";
import bedi from "../images/ceed/mentor-kds.png";
import amit from "../images/ceed/mentor-amit.png";
import sitaram from "../images/ceed/mentor-sitaram.png";
import vidushi from "../images/ceed/mentor-vedushi.png";
import apoorve from "../images/ceed/mentor-apoorv.png";
import kiran from "../images/ceed/mentor-kiran.png";
import dinesh from "../images/ceed/mentor-dinesh.jpg";
import ankit from "../images/ceed/mentor-ankit.jpg";
import anoop from "../images/ceed/mentor-anoop.jpeg";

const data = [{
  "image": nalin,
  "linkedIn": "https://www.linkedin.com/in/nalin-s-4536473/",
  "name": "Nalin Singh",
  "title": "Founder - Natio Cultus Consulting"
}, {
  "image": sunil,
  "linkedIn": "https://www.linkedin.com/in/sunil-girdhar-a233985/",
  "name": "Sunil Girdhar",
  "title": "Co-Founder - Natio Cultus Consulting"
}, {
  "image": sandhir,
  "linkedIn": "https://in.linkedin.com/in/sandhir-sharma-ph-d-mgmt-4933431a",
  "name": "Sandheer Sharma",
  "title": "Dean – Chitkara Business School"
}, {
  "image": vivek,
  "linkedIn": "https://www.linkedin.com/in/vivek-atray-9464434/",
  "name": "Vivek Attray",
  "title": "International Life Coach | Former IAS"
}
  , {
    "image": archana,
    "linkedIn": "https://in.linkedin.com/in/archana-mantri-69727625",
    "name": "Dr. Archana Mantri",
    "title": "Pro VC & Head research Chitkara University"
  }
  , {
    "image": bedi,
    "linkedIn": "",
    "name": "Dr. KDS Bedi",
    "title": "Principal- Chitkara College of Sales and Marketing"
  }, {
    "image": amit,
    "linkedIn": "",
    "name": "Amit Vashishth",
    "title": "Principal - Chitkara School of Hospitality"
  }, {
    "image": sitaram,
    "linkedIn": "",
    "name": "Sohrab Sitaram",
    "title": "Co-founder - \nKeventers"
  }, {
    "image": vidushi,
    "linkedIn": "https://www.linkedin.com/in/vidushimalhotra/",
    "name": "Vidushi Malhotra",
    "title": "Co-founder & Chief Strategist - Ingenious Faces"
  },
  {
    "image": kiran,
    "name": "Dr. Kiran Mehta",
    "title": "Prof. Of Financial Management - Chitkara University"
  },
  { "image": ankit, "name": "Ankit Bhateja", "title": "Co-Founder - Xovian Aerospace Pvt Ltd" },
  {
    "image": anoop,
    "name": "Anoop Ohri",
    "title": "Professor of Marketing - IIM Lucknow"
  }
];

export default data;
