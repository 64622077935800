import React from "react";
import poster from "../images/ceed/group-two.jpg";

const Partners = () => (
  <section id="poster" className="section poster">
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-heading">
              <h6 className="subtitle text-primary">COHORT - I</h6>
              {/*<h2 className="display-4">Our Supporters</h2>*/}
            </div>
          </div>
        </div>
        <div className="col-lg-12">
          <img src={poster}/>
          <p className="caption">CAP Cohort - I from batch of 2019-2020</p>
        </div>
      </div>
    </div>
  </section>
);

export default Partners;
