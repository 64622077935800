import React from "react";

import data from "../data/mentors-with-picture";

const Mentors = () => (
  <section id="mentors" className="section main mentors">

    <div className="container">
      <div className="row">
        <div className="col-lg-12">
          <div className="section-heading">
            <h6 className="subtitle text-primary">CAP MENTORS AND FACULTY</h6>
            <p>Entrepreneurs at CAP are being coached and mentored by the best of industry entrepreneurs, mentors and
              investors. The sessions will include broad topics as: Idea formation and development, customer
              segmentation and customer profiling, business modelling and opportunity analysis, product prototype
              development and lab exposure, cost and profit analysis, design and brand building, IPR and Technology
              transfer, competitor analysis and feasibility reports and test marketing.</p>
            {/*<h2 className="display-4">Meet the Experts</h2>*/}
          </div>
        </div>
      </div>

      <div className="row">
        {
          data.map((mentor, index) => {
            return (
              <div className="col-lg-2 col-md-3 column" key={index}>
                <div className="team-block">
                  <div className="image">
                    <img src={mentor.image} alt="" className="img-fluid"/>
                    {/*<ul className="list-inline">*/}
                    {/*<li className="list-inline-item">*/}
                    {/*<a href={mentor.linkedIn} target="_blank">*/}
                    {/*<i className="ion-social-linkedin"></i>*/}
                    {/*</a>*/}
                    {/*</li>*/}
                    {/*</ul>*/}
                  </div>
                  <div className="team-content">
                    <h4>{mentor.name}</h4>
                    <p className="occupation">{mentor.title}</p>
                  </div>
                </div>
              </div>

            );
          })
        }
      </div>
    </div>
  </section>
);

export default Mentors;
